/* trendingpost.css */

/* Styles for the trending posts container */
.trending-post {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    max-width: 1200px;
  }
  
  /* Styles for the post container */
  .post-container {
    margin: 20px;
    padding: 20px;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    width: calc(30% - 40px); /* Adjust the width as needed */
    box-sizing: border-box;
  }
  
  /* Styles for the post image */
  .image-post {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
  
  /* Styles for the post link */
  .post-link {
    text-decoration: none;
    color: #333;
    font-weight: bold;
  }
  
  .post-link:hover {
    opacity: 0.6;
  }
  
  /* Styles for the profile section */
  .flex-profile {
    display: flex;
    align-items: center;
   }
  
  .profile {
    width: 16px;
    height: 19px;
    border-radius: 50%;
    margin-right: 10px;
    /* Adjust dimensions and other properties as needed */
  }
  
  .username {
    font-size: 14px;
    }
  
  /* Media query for mobile devices */
  @media screen and (max-width: 768px) {
    .post-container {
      width: 100%;
    }
  
    .username {
      font-size: 14px;
   
    }
  
    /* Add more styles as needed for mobile devices */
  }
  