html,body{
      height:100%;
      padding:0px;
      margin:0px;
      background:#f6f6f6;
      
    }

.clearfix::after{
    content: '';
    display:block;
    clear:both;
}

.btn {
    padding: .5rem 1rem;
    background:black;
    color:#D1BB71;
    border:1px solid transparent;
    border-radius:.25rem;
    font-size:1.08em;
    font-family:'lora', serif;
}
.btn:hover{
    color:white !important;
    background:#005255;

}

.btn-big{
    padding:.7em 1.3em;
    line-height:1.3rem;
}

.text-input{
    padding:.7em 1rem;
    display:block;
    width:100%;
    border-radius: 5px;
    border:1px solid #e0e0e0;
    outline:none;
    color:#444;
    line-height:1.5rem;
    font-size:1.4em;
    font-family:'lora',serif;
}
.msg{
    width: 100%;
    margin:5px auto;
    padding:8px;
    border-radius:5px;
    list-style:none;
}

.success{
color:#3a6e3a;
border:1px solid #3a6e3a;
background:#bcf5bc
}




/*NAVBAR*/
/* header{
    background:#008489;
    height:66px;
} */

.page-wrapper{
    min-height:100%;
}

.page-wrapper a:hover{
    color:#006669;
    
}


/* POST SLIDER */
.post-slider{
    position:relative;
    
}
.post-slider .slider-title{
    text-align:center;
    margin:30px auto;
}
.post-slider .next{
    position:absolute;
    top: 50%;
    right:30px;
    font-size: 2em;
    color:#006669;
    cursor:pointer;
}

.post-slider .prev{
    position:absolute;
    top: 50%;
    left:30px;
    font-size: 2em;
    color:#006669;
    cursor:pointer;
}
.post-slider .post-wrapper{
width:84%;
height:350px;
margin:0px auto;
overflow:hidden;
padding:10px 0px 10px 0px;

}
.post-slider .post-wrapper .post{
    width:300px;
    height:330px;
    margin:0px 10px;
    display:inline-block;
    background:white;
    border-radius:5px;
    box-shadow: 1rem 1rem 1rem -1rem #a0a0a033
    }

    .post-slider .post-wrapper .post .post-info{
        height:130px;
        padding:0px 5px;
        
    }
    

.post-slider .post-wrapper .post .slider-image{
width: 100%;
height:200px;
border-top-left-radius:5px;
border-top-right-radius:5px;
}

/* CONTENT */
.content{
    width:90%;
    margin:30px auto 30px;
    }

.content .main-content{
    width: 70%;
    float:left;
}
.content .main-content.single{
    background:white;
    padding:20px 50px;
    font-size:1.1em;
    border-radius:5px;
}
.content .main-content.single.post-title{
    text-align:center;
    margin-bottom:40px;

}

.content .main-content .post{
    width:95%;
    height:270px;
    margin:20px auto;
    border-radius:5px;
    background:white;
    position:relative;
}


.content .main-content .post .post-preview i{
    color:#757575;
    font-size: .8em;
}
.content .main-content .post .preview-text{
    line-height:1.5em;
    font-size:1.03em;
    color:#444;
}

    

.content .main-content .post .read-more{
position:absolute;
bottom:10px;
right:10px;
border:1px solid #006669;
background:transparent;
border-radius:0px;
color:#006669 !important;

}
.content .main-content .post .read-more:hover{
    background:#006669;
    color:white !important;
    transition:.25s;
}

.content .main-content .post .post-image{
    width:40%;
    height:100%;
    float:left;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.content .popular .post{
    border-top:1px solid #e0e0e0;
    margin-top:10px;
    padding-top:10px;
}
.content .popular .post img{
    height:60px;
    width:75px;
    float:left;
    margin-right:10px;
}

.content .main-content .post .post-preview{
    width:60%;
    padding:10px;
    float:right;
}

.content .main-content .recent-post-title{
    margin:20px;
}

/* .content .sidebar{
    width:30%;
    float:left;
}
.content .sidebar.single{
padding:0px 10px;
}

.content .sidebar .section{
    background:white;
    padding:20px;
    border-radius:5px;
    margin-bottom:20px;

} 
.content .sidebar .section .section-title{
    margin:10px 0px 10px 0px;
}

.content .sidebar .section.search{
margin-top:80px;
}


.content .sidebar .section.topics ul{
    margin:0px;
    padding:0px;
    list-style:none;
    border-top:1px solid #e0e0e0;

}

.content .sidebar .section.topics ul li a{
    display:block;
    padding:15px 0px 15px 0px;
    border-bottom:1px solid #e0e0e0;
    transition:all 0.3s;

}

.content .sidebar .section.topics ul li a:hover{
    padding-left:10px;
    transition:all 0.3s;

} */

.comment-section {
    margin-top: 20px;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .comments-list {
    margin-bottom: 50px;
   }
   .commented {
    width: 930px;
   }
  
   .comment-container {
    display: flex;
    align-items:baseline;
   }
  
  .comment {
    margin-bottom: 10px;
    padding: 8px;
    background-color: #f5f5f5;
    border-radius: 5px;
  }
  
  .comment-input {
    display: flex;
    margin-bottom: 20px;
  }
  
  .comment-input input {
    padding:.7em 1rem;
    display:block;
    width:50%;
    border-radius: 5px;
    border:1px solid #e0e0e0;
    outline:none;
    color:#444;
    line-height:1.5rem;
    font-size:1.4em;
    font-family:'lora',serif;
  }
  
  .comment-input button {
    padding: 8px 16px;
    background-color: black;
    color:  #D1BB71;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .comment-input button:hover {
    background-color: #0056b3;
  }

  



  
 
  .textarea:focus {
    border-color: #0056b3;
    box-shadow: 0 0 5px rgba(0, 86, 179, 0.7);
  }


  /* Common styles for both desktop and mobile */





.social-share-buttons {
    display: flex;
    gap: 10px;
   
  }



  /* Common styles for all social share buttons */
.social-share-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Facebook button styles */
  .facebook-share-button {
    background-color: #1877f2; /* Facebook blue color */
  }
  
  /* Twitter button styles */
  .twitter-share-button {
    background-color: #1da1f2; /* Twitter blue color */
  }
  
  /* Adjust styles on hover */
  .social-share-button:hover {
    filter: brightness(1.2); /* Increase brightness on hover */
  }
  
  
  
  
  /* Mobile styles */
  @media (max-width: 767px) {
    .social-share-buttons {
      flex-direction: column; /* Align buttons vertically on mobile */
    }
  }
  
  

  .textarea {
    width: calc(100% - 110px); /* Adjust the button width as per your design */
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    outline: none;
    color: #444;
    line-height: 1.5rem;
    font-size: 1.4em;
    font-family: 'lora', serif;
    resize: vertical;
  }
  
  @media screen and (max-width: 767px) {
    .textarea {
      width: calc(100% - 20px); /* Adjust the button width as per your design */
    }
  }
  
  








@media screen and (max-width: 767px) {
    .page-wrapper {
      padding: 0 10px; 
    }
    
    .post-title {
      font-size: 24px; 
    }
  
    .post-content {
      font-size: 16px; 
    }
  
    .comment-section {
      padding: 20px 0; 
    }
  
    .comment-input {
      display: block;
      margin-top: 20px; 
    }
  
    .comment-input input[type="text"] {
      width: calc(100% - 20px); 
      margin-bottom: 10px; 
    }
  
    .comment-input button {
      width: 100%; 
    }

    .comment-input input {
        width: 100%; 
      }
    
      .commented {
        width: 100%; 
      }
  }
  