
@media only screen and (max-width: 768px) {
    .pic {
      display: flex;
      flex-direction: column;
      align-items: center;
   
    }
    .aboutimage{
      /* max-width: 100%; 
      margin-top: 1rem;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: -1;  */
      display: none;
    }
  
    .about-text {
      text-align: center;
      margin: 2rem 1rem; /* Adjust margins as needed */
    }

    
      img {
        max-width: 100%; /* Ensures the image doesn't overflow its container */
      }
  
    /* Adjust specific elements for mobile */
    H1Tags {
      margin: 2rem 0; /* Adjust margins as needed */
      /* Add other mobile-specific styles */
    }
  
    PTags {
      margin-bottom: 1rem; /* Adjust margins as needed */
      /* Add other mobile-specific styles */
    }
  }
  