.container-column{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.container-flex {
    display: flex;
    flex-direction: row;
}
.cont {
    width: 100%;
    height: 100vh;
    background-image: url("../../Images/pic.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    background-position: 100% calc(100% - 5rem); 
}

.container{
    box-sizing: border-box;
}

@media screen and (min-width: 750px) and (max-width:1092px) {
    .cont {
        background-size: 50%;
        background-position: 108% calc(100% - 5rem); 
    }
}

@media screen and (min-width: 1093px) {    
    .cont {
        background-image: url("../../Images/pic.png");
        background-size: 50%;
        background-position: 109% -30%;
    }
    .container-column {
        flex-direction: row;
    }

    .container-flex {
        flex-direction: column;
    }
}




