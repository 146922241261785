.register-form-container {
   margin: 0 auto;
   width: 95%;
   box-sizing: border-box;
}

.hidden{
    display:none
}

.form-control-reg{
    width:100%;
    display: flex;
    flex-direction: column;
}

.form-control-reg input,
select {
    height: 2rem;
    margin-bottom: 1rem;
    font-size: large;
}

.form-control-check {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    margin-bottom: 1rem;
}

.check {
    width: fit-content;
    height: fit-content;
    font-size: small;
    margin: 0;
}


@media screen and (min-width:800px) {
    .register-form-container{
        width: 60%;
    }
    
}