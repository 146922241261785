form{
    margin-top: 2rem;
    width: 100%;
}

.form-control-login{
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.form-control-login input, select{
    height: 2rem;
    margin-bottom: 1rem;
    font-size: large;
}

.form-control-login button{
    height: 3rem;
    width: 8rem;
    font-size: larger;
    background-color: black;
    border: none;
    border-radius: 5px;
    color:#D1BB71;
}

@media screen and (min-width:800px) {
    .form-control-login{
        width: 60%;
    }

    .form-control-check {
        width: 60%;
    }
}