.nav-admin{
 
    font-size: larger;
    width: 100%;
    margin:0;
    padding: 0;
}

.nav-admin li{
    padding: 0.7rem 0.3rem;
    list-style-type: none;
    border-bottom: 1px gray solid;
    width: 100%;
    box-sizing: border-box;
}

.nav-admin a {
    color: #D1BB71;
    text-decoration: none;
}

.post-form-container{
    margin: 0;
    padding: 2rem 1rem 0 0;
    background-color: white;
    height: 100%;
    position: fixed;
    left: 5%;
    width: 90%;
    top: 1rem;
    z-index: 2;
    animation-name: examples;
    animation-duration: 1s;
    text-align: left;
    font-size: larger;
    box-sizing: border-box;
    border-radius: 10px;
    overflow-x: auto;
}

.form-control-post {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.form-control-post input
{
    height: 1.5rem;
    margin-bottom: 0.8rem;
    font-size: large;
}

.form-control-post select{
    height: 1.8rem;
}

textarea{
    height: 5rem;
    margin-bottom: 0.5rem;
    font-size: larger;
}

.view-stories-container{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;  
    padding-left: 0.2rem;
    margin:0 0 0.4rem 0;
    box-sizing: border-box;
    font-size: large;
}

.stories-p{
    width: 100%; 
    font-size: 18px; 
    font-weight: 600;
 }

 .view-stories-container div{
    width: 100%;
    text-align: left;
 }

.view-stories-container a{
    margin: 0.5rem;
    box-sizing: border-box;
    text-decoration: none;
}

.edit-btn{
   color: blue;   
}

.delete-btn{
    color: red;
}

.submit-btn{
    color: #D1BB71;
    background-color: black;
    height: 3rem;
    width:10rem ;
    border-radius: 5px;
    font-size: large;
    border: none;
}

.submit-btn:disabled{
    background-color: gray;
    color:black;
}

.image-container {
    width: 100%;
    max-width: 300px; 
    height: auto;
    overflow: hidden;
    border: 1px solid #ccc; 
  }
  
  .image-container input[type="file"] {
    width: 100%;
    height: auto;
    display: block;
  }
  
.loader-container {
    background-color: #e8e9eb;
    z-index: 3;
    height: 100%;
    position: fixed;
    width: 100%;
    top: 0;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite
}

.loader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid black;
    animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg)
    }
}

@keyframes prixClipFix {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
    }

    25% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
    }

    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
    }

    75% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)
    }

    100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0)
    }
}
@media screen and (min-width: 800px) {
    .image-container {
      max-width: 100%; 
    }
  }
  

@media screen and (min-width:800px) {
    .post-form-container{
        width: 80%;
        left:10%;
    }

    .form-control-post{
        width: 80%;
    }

    .view-stories-container{
        flex-direction: row;
    }

    .stories-p {
        width: 80%;
        font-size: 18px;
        font-weight: 600;
    }

    .view-stories-container div {
        width: 20%;
        text-align: left;
    }
}

@keyframes examples {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translate(0%);
    }
}
