header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;  
    box-sizing: border-box;
    height: 9rem;
    align-items: center;
    position: relative;
    z-index: 2;

}

.logo {
    transform: scale(0.8);
   
    margin-left:-9rem;

}

/* .logo {
    transform: scale(0.8); 
    width: 400px; 
    height: auto; 
} */

header ul{
    display: flex;
    width: 50%;
    justify-content: space-around;
    align-items: center;
    list-style-type: none;
    text-align: center;
}

header a{
    text-decoration: none;
    font-weight: 600;
}

header h1{
    width: 20%;
    text-align: left;
}


.hamburger-container {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    cursor: pointer; 
  }

.hamburger {
    border: none;
    background-color: inherit;
    width:2rem;
    height:2rem;
    display:flex;
    justify-content: space-around;
    flex-flow: column nowrap;
}

.hamburger span {
    width: 2rem;
    height: 0.5rem;
    border-radius: 10px;
    background-color: #D1BB71
}

.profile{
    height: 30px;
    width: 30px;
    background-color: #D1BB71;
}



.dropdown{
    float: left;
    overflow: hidden;
    font-size: 16px;
    border: none;
    outline: none;
    color: white;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    /* Important for vertical align on mobile phones */
    margin: 0;
    /* Important for vertical align on mobile phones */
}

/* Add a red background color to navbar links on hover */
.navbar a:hover,
.dropdown:hover .dropbtn {
    background-color: red;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
    background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}



@media only screen and (max-width: 767px) {
    /* For Larger screens: */
   
    .logo {
       transform: scale(1.2);
       margin-left: -2.5rem;
        width: 320px; 

    }


    .hamburger-container {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        cursor: pointer; 
        margin-left: 30px;
    }
    
    .hamburger {
        border: none;
        background-color: inherit;
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: space-around;
        flex-flow: column nowrap;
      
    }
    
    .hamburger span {
        width: 2rem;
        height: 0.5rem;
        border-radius: 10px;
        background-color: #D1BB71;
    
    }

    
}

@media only screen and (min-width: 900px) and (max-width: 1000px) {
  /* For Phones: */
    header ul {
        width: 70%;
    }

}

@media screen and (max-width:700px) {

    .pic img {
            height: 80%;
            position: fixed;
            z-index:2
        }
    
}