body {
  margin: 0;
  
  font-family: 'Poppins', sans-serif;
  /* font-family: 'Roboto', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo,Monaco, Consolas, 'Courier New',
    monospace;
}

.banners-div{
  width: 100%;
  font-size:15px;
  box-sizing: border-box;
}

.recent-cont-posts {
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
}

.recent-cont-vids {
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
}

.recent-img{
  width: 100%;
  height: 20rem;
}

.btn{
  background-color: white;
  border: 1px black solid;
  padding:0.3rem 0.5rem;
}

.social{
  margin-left: 0.4rem;
  border-radius: 2px;
}

.error {
  color: #884b4b;
  border: 1px solid #884b4b;
  background: #f5bcbc;
  padding: 0.3rem;
  border-radius: 5px;
}

.success{
  color: #155724;
  border: 1px solid #155724;
  background: #D4EDDA;
  padding: 0.3rem;
}

.pic {
  height: 30%;
  width: 100%;
  text-align: left;
}

.pic img {
  float: right;
  opacity: 0.7;
}

@media screen and (min-width:915px) {
  .banners-div{
    margin-top: -6rem;
    width: 55%;
    font-size: x-large;
  }

  .about-text {
    padding: 0 7rem;
  }

  .recent-cont-vids{
    width: 30%;
  }

    .recent-cont-posts {
      width: 60%;
    }

  /* .recent-img {
    height: 100px;
    width: 400px;
  } */

    .recent-img {
      height: 8rem;
      width: 100%;
    }

}



@media screen and (min-width:700px) and (max-width:914px){
  .recent-cont-posts{
    width:70%;
    margin: 0 auto;
  }


  .recent-cont-vids{
    width: 70%;
    margin: 0 auto;
  }
}